<template>
  <div>
    <h1 class="heading">My rooms</h1>
    <div class="rooms_header">
      <div style="flex:1;margin-right:15px">
        <v-select
            v-model="objectItem"
            hide-details
            :items="objects.list"
            item-value="id"
            item-text="name"
            return-object
            style="max-width: 300px"
            outlined
            small
            dense
            @change="changeHotel"
        >
          <template v-slot:selection="{ item }">
                            <div v-if="$store.state.isMobile">{{ item.name.slice(0,5) }}...</div>
                <div v-if="!$store.state.isMobile">{{ item.name }}</div>
            <div v-if="item.tariff_id === 1" class="tariff_obj">free</div>
            <div v-if="item.tariff_id === 2" class="tariff_obj">standart</div>
            <div v-if="item.tariff_id === 3" class="tariff_obj">pro</div>
          </template>
          <template v-slot:item="{ item }">
                            <div v-if="$store.state.isMobile">{{ item.name.slice(0,5) }}...</div>
                <div v-if="!$store.state.isMobile">{{ item.name }}</div>
            <div v-if="item.tariff_id === 1" class="tariff_obj">free</div>
            <div v-if="item.tariff_id === 2" class="tariff_obj">standart</div>
            <div v-if="item.tariff_id === 3" class="tariff_obj">pro</div>
          </template>
        </v-select>
      </div>
      <div style="flex:1;display: flex;justify-content: flex-end">
      <v-btn color="primary"
             outlined
             small
             dense @click="openAddRoomCategory"
        >+ Add category</v-btn
      >
      </div>
    </div>
    <div class="roomsBody" style="margin-top:15px;">
      <div style="margin-bottom: 15px;">
        <v-data-table
          class="table"
          :headers="headers"
          :items="roomCategories"
          hide-default-footer
          :items-per-page="999"
        >
          <template v-slot:body="{ items }">
            <tbody v-if="items.length">
              <tr v-for="item in items" :key="item.id">
                <td class="d-block d-sm-table-cell" style="text-align: center;">
                  {{ item.name }}
                </td>
                <td class="d-block d-sm-table-cell" style="text-align:center;cursor: pointer;" @click="openRoomList(item)">
                  {{ item.rooms.length }}
                </td>
                <td class="d-block d-sm-table-cell" style="text-align: center;">
                  {{ formatDate(item.created_at) }}
                </td>
                <td class="d-block d-sm-table-cell" style="text-align: center;">
                  <v-menu offset-y open-on-hover bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" dark v-bind="attrs" v-on="on"
                             outlined
                             small
                             dense>
                        <v-icon>mdi-cog</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        style="cursor:pointer;"
                        @click="openEditCategoryRoom(item)"
                      >
                        <v-list-item-title>Edit category</v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title
                          style="cursor:pointer;color:red;"
                          @click="deleteCategory(item.id)"
                          >Delete</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title
                          style="cursor:pointer;"
                          @click="openAddRoomDialog(item)"
                        >
                          Create room
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title
                          style="cursor:pointer;"
                          @click="openRoomList(item)"
                        >
                          Room list
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </tbody>
            <tbody v-if="items.length === 0">
              <tr>
                <td class="d-block d-sm-table-cell"
                  colspan="4"
                  style="height:85px;text-align: center;padding:0 15px;font-weight: normal;"
                >
                  You don't have room categories yet, it's time to create them
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </div>
    </div>
    <v-dialog :fullscreen="$store.state.isMobile" v-model="addRoomCategory" max-width="500px">
      <v-card style="padding-bottom: 15px;">
        <v-icon
          style="top: 10px; right: 10px; position: absolute"
          class="close__modal"
          @click="closeAddRoomCategory"
          >mdi-close</v-icon
        >
          <v-container style="padding: 12px 24px;">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <span class="text-h6">New room category</span>
              </v-col>
            </v-row>
          </v-container>
        <v-card-text>
        <label class="label">
          Category
        </label>
        <v-text-field style="margin-top:15px;" v-model="addRoomCategoryModel" outlined dense>

        </v-text-field>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn
            color="primary"
            outlined
            small
            dense
            @click="saveAddRoomCategory"
            >Create</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog :fullscreen="$store.state.isMobile" v-model="editRoomCategory" max-width="500px">
      <v-card>
        <v-icon
          style="top: 10px; right: 10px; position: absolute"
          class="close__modal"
          @click="closeEditRoomCategory"
          >mdi-close</v-icon
        >
          <v-container style="padding: 12px 24px;">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <span class="text-h6">Edit room category</span>
              </v-col>
            </v-row>
          </v-container>
        <v-card-text>
        <label class="label">
          Category
        </label>
        <v-text-field style="margin-top:15px;" v-model="editRoomCategoryModel.name" outlined dense>

        </v-text-field>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn
            color="primary"
            class="bottomButtons"
            outlined
            small
            dense
            @click="saveEditRoomCategory"
            >Edit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog :fullscreen="$store.state.isMobile" v-model="addRoomDialog" max-width="700px">
      <v-card>
        <v-icon
          style="top: 10px; right: 10px; position: absolute"
          class="close__modal"
          @click="closeAddRoomDialog"
          >mdi-close</v-icon
        >
        <v-card-title>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <span class="text-h5">New Room</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
            <div style="display: flex;justify-content: space-between;align-items: center;">

                <div style="flex:1;">
                    <label class="label">Change room category</label>
                    <v-select dense outlined item-text="name" item-value="id" :items="roomCategories" v-model="addRoom.room_category_id" style="margin-top:15px;">
                    </v-select>
                </div>
                <div style="flex:1;margin-left:15px;">
                    <label class="label">Change room id</label>
                    <v-text-field dense outlined v-model="addRoom.room_id" style="margin-top:15px;"></v-text-field>
                </div>
            </div>


          <v-tabs >
              <v-tab v-for="(selected_lang, i) in selected_langs" :key="i">
                  <img
                      :src="landing.advanced.langs.find((x) => x.value === selected_lang).flag"
                      alt=""
                      style="width: 20px; height: 15px; margin-right: 5px"
                  />
                  {{ selected_lang }}
              </v-tab>
            <v-tab-item v-for="lang in selected_langs" :key="lang">
              <div style="margin-top:15px;">
              <label class="label">Change room name</label>
              <v-text-field dense outlined v-model="addRoom.name[lang]" style="margin-top:15px;max-width: 50%;"></v-text-field>
              </div>
            </v-tab-item>
          </v-tabs>
          <div style="display:flex;width:100%;justify-content:space-between;align-items:center;">
            <label class="label" style="margin:0;">Add range to rooms</label>
            <v-switch color="#6ab64a" v-model="showRange"></v-switch>
          </div>
          <div v-if="showRange">
            <div style="display:flex;">
              <div style="flex:1;margin-right:15px;">
              <label class="label">Range start</label>
              <v-text-field dense outlined type="number" v-model.number="addRoomStartRange" number style="margin-top:15px;"></v-text-field></div>
              <div style="flex:1;">
              <label class="label">Range end</label>
              <v-text-field type="number" dense outlined number v-model.number="addRoomEndRange" style="margin-top:15px;"></v-text-field>
              </div>
            </div>
          </div>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn
            color="primary"
            outlined
            dense
            class="bottomButtons"
            small
            @click="confirmAddRoom"
            >Create</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog :fullscreen="$store.state.isMobile" v-model="editRoomDialog" max-width="700px">
      <v-card>
        <v-icon
          style="top: 10px; right: 10px; position: absolute"
          class="close__modal"
          @click="closeEditRoomDialog"
          >mdi-close</v-icon
        >
        <v-card-title>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <span class="text-h5">Edit Room</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
            <div style="display: flex;justify-content: space-between;align-items: center;">
                <div style="flex:1;">
                    <label class="label">Change room category</label>
                    <v-select dense outlined item-text="name" item-value="id" :items="roomCategories" v-model="editRoom.room_category_id" style="margin-top:15px;">

                    </v-select>
                </div>
                <div style="flex:1;margin-left:15px;">
                    <label class="label">Change room id</label>
                    <v-text-field dense outlined v-model="editRoom.room_id" style="margin-top:15px;"></v-text-field>
                </div>
            </div>
          <v-tabs >
              <v-tab v-for="(selected_lang, i) in selected_langs" :key="i">
                  <img
                          :src="
              landing.advanced.langs.find((x) => x.value === selected_lang).flag
            "
                          alt=""
                          style="width: 20px; height: 15px; margin-right: 5px"
                  />
                  {{ selected_lang }}
              </v-tab>
            <v-tab-item v-for="lang in selected_langs" :key="lang">
              <div style="margin-top:15px;">
              <label class="label">Change room name</label>
              <v-text-field dense outlined v-model="editRoom.name[lang]" style="margin-top:15px;max-width: 50%;"></v-text-field>
              </div>
            </v-tab-item>
          </v-tabs>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn
            color="primary"
            class="bottomButtons"
            outlined
            small
            dense
            @click="confirmEditRoom"
            >Edit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog :fullscreen="$store.state.isMobile" v-model="listRoomsDialog" max-width="900px">
    <v-card style="min-height:1055px;">
        <v-card-title style="display:flex;justify-content:space-between;align-items:center;">
          <div style="margin-top:15px;" v-if="objectItem">List Rooms of {{objectItem.name}}</div>
          <v-icon
          style="top: 10px; right: 10px; position: absolute"
          class="close__modal"
            @click="closeRoomList"
          >mdi-close</v-icon>
          <v-btn
              style="cursor:pointer;margin-top:15px;"
              color="primary"
              outlined
              small
              dense
              @click="openAddRoomDialog(selectedCategory)"
          >
            Create room
          </v-btn>
        </v-card-title>
        <div style="padding:15px;">
          <div style="margin-bottom: 15px;">
          <v-tabs >
              <v-tab v-for="(selected_lang, i) in selected_langs" :key="i">
                  <img
                          :src="
              landing.advanced.langs.find((x) => x.value === selected_lang).flag
            "
                          alt=""
                          style="width: 20px; height: 15px; margin-right: 5px"
                  />
                  {{ selected_lang }}
              </v-tab>
            <v-tab-item v-for="lang in selected_langs" :key="lang">
            <v-data-table
              :headers="headersRoom"
              :page.sync="page"
              @page-count="pageCount = $event"
              hide-default-footer
              :items="listRooms"
              class="table"
            >
              <template v-slot:body="{ items }">
                <tbody v-if="items.length">
                  <tr v-for="item in items" :key="item.id">
                    <td class="d-block d-sm-table-cell" style="text-align: center;">{{ item.room_id }}</td>
                    <td class="d-block d-sm-table-cell" style="text-align: center;">
                      {{ item.name[lang] }}
                    </td>
                    <td class="d-block d-sm-table-cell" style="text-align: center;">
                      {{ formatDate(item.created_at) }}
                    </td>
                    <td class="d-block d-sm-table-cell" style="text-align: center;">
                      <v-menu offset-y open-on-hover bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            dark
                            outlined
                            small
                            dense
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>mdi-cog</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                            style="cursor:pointer;"
                            @click="editRoomDialogOpen(item)"
                          >
                            <v-list-item-title>Edit room</v-list-item-title>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-title
                              @click="deleteRoomOpen(item)"
                              style="cursor:pointer;color:red;"
                              >Delete</v-list-item-title
                            >
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="items.length === 0">
                  <tr>
                    <td class="d-block d-sm-table-cell"
                      colspan="4"
                      style="text-align: center;padding:0 15px;font-weight: normal;"
                    >
                      You don't have rooms yet, it's time to create them
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>

    <div class="text-center pt-2">
      <v-pagination v-if="pageCount>1"
        v-model="page"
        :length="pageCount"
      ></v-pagination></div>
            </v-tab-item>
          </v-tabs>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <PopupSuccess ref="PopupSuccess" :title="'Thank you!'" :content="successMessage"/>
    <PopupDelete ref="PopupDelete" title="Are you sure about delete room category?" @confirm="deleteCategoryRoom"/>
    <PopupDelete ref="PopupDeleteRoom" title="Are you sure about delete room category?" @confirm="deleteRoomConfirm"/>
  </div>
</template>

<script>
import store from "@/store";
import { mapActions, mapState } from "vuex";
import PopupSuccess from "@/components/Popup/PopupSuccess.vue"
import moment from "moment"
import PopupDelete from "@/components/Popup/PopupDelete.vue"
import titleMixin from "@/mixins/titleMixin";
export default {
  name: "Rooms",
  title(){
    return 'Rooms'
  },
  mixins:[titleMixin],
  data() {
    return {
      objectItem: null,
      addRoomDialog: false,
      editRoomDialog: false,
      addRoomStartRange:0,
      addRoomEndRange:1,
      showRange:false,
      addRoomCategory:false,
      listRooms:[],
      addRoomCategoryModel:"default category name",
      editRoomCategory:false,
      editRoomCategoryModel:"default category name",
      successMessage:"",
      defaultRoom:{
        room_category_id:null,
        hotel_id:null,
        room_id:"default room id",
        name:{
          en:'default name'
        }
      },
      addRoom:{
        room_category_id:null,
        hotel_id:null,
        room_id:"default room id",
        name:{
          en:'default name'
        }
      },
      page: 1,
      pageCount: 0,
      editRoom:{
        room_category_id:null,
        hotel_id:null,
        room_id:"default room id",
        name:{
          en:'default name'
        }
      },
      headers: [
        { text: "Name of category", value: "name", align: "center", sortable: true },
        {
          text: "Rooms",
          value: "rooms",
          align: "center",
          sortable: true,
        },
        {
          text: "Created",
          value: "created_at",
          align: "center",
          sortable: true,
        },

        { text: "Options", align: "center", sortable: true },
      ],
      headersRoom: [
        { text: "Room ID", value: "room_id", align: "center", sortable: true },
        { text: "Name", value: "name", align: "center", sortable: true },
        {
          text: "Created",
          value: "created_at",
          align: "center",
          sortable: true,
        },
        { text: "Options", align: "center", sortable: true },
      ],
      deleteCategoryId:null,
      deleteRoomId:null,
      editRoomCategoryId:null,
      listRoomsDialog:false,
      savedCategoryId:null,
      selectedCategory:null
    };
  },
  components:{
    PopupSuccess,
    PopupDelete
  },
  beforeRouteEnter(to, from, next) {
      if(!store.state.objects.list.length){
          store.dispatch('objects/getList')
              .then((data) => {
                  next();
              }).catch(() => {
              next();
          })
      }else{
          next();
      }
  },
  computed: {
    ...mapState(["objects"]),
    ...mapState('rooms',['roomCategories']),
    ...mapState("landing", ["selected_langs"]),
      ...mapState(["landing"]),
    roomList(){
      let arr = [];
      for(let i = 0;i<this.roomCategories.length;i++){
        for(let index = 0;index < this.roomCategories[i].rooms.length;index++){
          const room = JSON.parse(JSON.stringify(this.roomCategories[i].rooms[index]));
          room.room_category = this.roomCategories[i].name;
          arr.push(room)
        }
      }
      return arr;
    }
  },
  methods: {
    ...mapActions('rooms',['getRoomCategories','createdNewRoomCategory','createNewRoom','deleteRoomCategory','changeRoomCategory','deleteRoom','changeRoom']),
    editRoomDialogOpen(item){
      this.editRoom = JSON.parse(JSON.stringify(item));
      this.savedCategoryId = item.room_category_id;
      this.editRoomDialog = true;
    },
    closeEditRoomDialog(){
      this.editRoomDialog = false;
      this.editRoom = JSON.parse(JSON.parse(this.defaultRoom));
    },
    async confirmEditRoom(){
      if(this.editRoom.room_category_id !== this.savedCategoryId){
        this.editRoom.old_category = this.savedCategoryId;
      }
      await this.changeRoom(this.editRoom);
      this.editRoomDialog = false;
      this.editRoom = JSON.parse(JSON.stringify(this.defaultRoom));
      this.successMessage = 'Your room edited successfull!';
      this.$refs.PopupSuccess.open();
    },
    deleteRoomOpen(item){
      this.deleteRoomId = item.id;
      this.$refs.PopupDeleteRoom.open();
    },
    deleteRoomConfirm(){
      this.deleteRoom(this.deleteRoomId);
      this.successMessage = 'Your room deleted successfull!';
      this.$refs.PopupSuccess.open();
    },
    closeRoomList(){
      this.listRoomsDialog = false;
      this.listRooms = [];
      this.selectedCategory = null;
    },
    openRoomList(item){
      this.selectedCategory = item;
      this.listRooms = item.rooms;
      this.listRoomsDialog = true;
    },
    deleteCategory(id){
      this.deleteCategoryId = id;
      this.$refs.PopupDelete.open();
    },
    async deleteCategoryRoom(){
      await this.deleteRoomCategory(this.deleteCategoryId);
      this.deleteCategoryId = null;
      this.successMessage = 'You room category deleted successfull!';
      this.$refs.PopupSuccess.open();
    },
    openEditCategoryRoom(item){
      this.editRoomCategoryModel = JSON.parse(JSON.stringify(item));
      this.editRoomCategory = true;
    },
    formatDate(date) {
      return moment(date).format("DD.MM.yyyy HH:mm");
    },
    async confirmAddRoom(){
      const payload = JSON.parse(JSON.stringify(this.addRoom));
      if(this.showRange){
        payload.range_start = +this.addRoomStartRange;
        payload.range_end = +this.addRoomEndRange;
      }
      await this.createNewRoom(payload);
      const candidate = this.roomCategories.find(x => x.id === payload.room_category_id);
      if(candidate){
        this.listRooms = candidate.rooms;
      }
      this.addRoomDialog = false;
      this.successMessage = 'New room added successfull!'
      this.$refs.PopupSuccess.open();
    },
    closeAddRoomCategory(){
      this.addRoomCategory = false;
      this.addRoomCategoryModel = "default category name"
    },
    async saveAddRoomCategory(){
      const payload = {
        hotel_id:this.objectItem.id,
        name:this.addRoomCategoryModel
      }
      await this.createdNewRoomCategory(payload);
      this.successMessage = 'New room category created succesfull!'
      this.addRoomCategory = false;
      this.addRoomCategoryModel =  "default category name";
      this.$refs.PopupSuccess.open();
    },
    closeEditRoomCategory(){
      this.editRoomCategory = false;
      this.editRoomCategoryModel = JSON.parse(JSON.stringify(this.defaultRoom));
    },
    async saveEditRoomCategory(){
      this.successMessage = 'Room category edited succesfull!'
      this.editRoomCategory = false;
      await this.changeRoomCategory(this.editRoomCategoryModel);
      this.$refs.PopupSuccess.open();
      this.editRoomCategoryModel = JSON.parse(JSON.stringify(this.defaultRoom));
    },
    async changeHotel() {
      await this.getRoomCategories(this.objectItem.id);
      await store.dispatch("landing/getHotelLangs", this.objectItem.id);
      this.defaultRoom.hotel_id = this.objectItem.id;
      if(this.roomCategories.length){
        this.defaultRoom.room_category_id = this.roomCategories[0].id;
      }
      for (let i = 0; i < this.selected_langs.length; i++) {
        if (!this.defaultRoom.name[this.selected_langs[i]]) {
          this.defaultRoom.name[this.selected_langs[i]] =
            this.defaultRoom.name.en.split("").join("");
        }
        }
    },
    openAddRoomDialog(item) {
      this.addRoomStartRange = 0;
      this.addRoomEndRange = 1;
      this.showRange = false;
      this.defaultRoom.room_category_id = item.id;
      this.addRoom = JSON.parse(JSON.stringify(this.defaultRoom));
      this.addRoomDialog = true;
    },
    closeAddRoomDialog() {
      this.addRoomDialog = false;
      this.addRoom = JSON.parse(JSON.stringify(this.defaultRoom));
    },
    openAddRoomCategory(){
      this.addRoomCategory = true;
    }
  },
  async mounted() {
    if (this.objects.list.length) {
      if (this.$route.query.object) {
        const candidate = this.objects.list.find(
          (x) => +x.id === +this.$route.query.object
        );
        if (candidate) {
          this.objectItem = candidate;
        } else {
          this.objectItem = this.objects.list[0];
        }
      } else {
        this.objectItem = this.objects.list[0];
      }
      await this.getRoomCategories(this.objectItem.id);
      await store.dispatch("landing/getHotelLangs", this.objectItem.id);
      this.defaultRoom.hotel_id = this.objectItem.id;
      if(this.roomCategories.length){
        this.defaultRoom.room_category_id = this.roomCategories[0].id;
      }
      for (let i = 0; i < this.selected_langs.length; i++) {
        if (!this.defaultRoom.name[this.selected_langs[i]]) {
          this.defaultRoom.name[this.selected_langs[i]] =
            this.defaultRoom.name.en.split("").join("");
        }
        }
    }
  },
};
</script>

<style lang="scss">
.rooms_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bottomButtons{
  margin-bottom:15px;
}
.tariff_obj {
  margin-left:5px;
  display: inline-block;
  padding: 4px 6px;
  text-transform: uppercase;
  border-radius: 0 5px 0 5px;
  background: #007db5;
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  color: #fff;
}
</style>
